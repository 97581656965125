import React, { useRef, useState } from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { PageContainer, ProTable } from '@ant-design/pro-components'
import type { ActionType } from '@ant-design/pro-components'
import { Badge, Button, Tag } from 'antd'

import { columnsSummaryItem } from 'components/summary-item.columns'
import { columnsSummaryPo } from 'components/summary-po-column.columns'
import { generateSummaryOfOrderFilter } from 'helpers/summary-of-order'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { SummaryApi } from 'services/summary-order-api'
import SummaryItem from 'summary.types'
import * as XLSX from 'xlsx'

// const PAGE_SIZE = 5

const SummaryOfOrder = () => {
  const actionRef = useRef<ActionType>()
  const [activeKey, setActiveKey] = useState<React.Key>('item')
  const [userData] = useRecoilState(userAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const [items, setItems] = useState<any>([])
  const [total_qty, setTotalQty] = useState(0)
  const [total_amount, setTotalAmount] = useState(0)
  const PAGE_SIZE = useRef<number>(5)

  const summaryOfOrderMutation = useMutation(async (data: any) => {
    const api = useApi(SummaryApi)
    return api.summaryOfOrder(data)
  })

  const request = async (params: any = {}, _sort, _filter) => {
    // console.log('filter', filter, params)
    if (
      params &&
      params.pageSize &&
      params.pageSize > 0 &&
      params.pageSize != PAGE_SIZE.current
    ) {
      PAGE_SIZE.current = params.pageSize
    }
    const where = generateSummaryOfOrderFilter(params)
    // console.log('order pagesize : ' + PAGE_SIZE)
    const result = await summaryOfOrderMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: where,
      },
      params: {
        option: activeKey,
        offset: (currentPage - 1) * PAGE_SIZE.current,
        limit: PAGE_SIZE.current,
      },
    })

    if (result.kind == 'ok') {
      setItems(result.data?.data || [])
      setTotalQty(result.data?.sum_order_qty || 0)
      setTotalAmount(result.data?.sum_total_amount || 0)
    }

    return result.data || {}
  }

  const renderBadge = (count: number, active = false) => {
    return (
      <Badge
        count={count}
        style={{
          marginTop: -2,
          marginLeft: 4,
          color: active ? '#1890FF' : '#999',
          backgroundColor: active ? '#E6F7FF' : '#eee',
        }}
      />
    )
  }

  const exportToExcel = async () => {
    const result = await summaryOfOrderMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [],
      },
      params: {
        option: activeKey,
        // offset: (currentPage - 1) * PAGE_SIZE,
        // limit: PAGE_SIZE,
      },
    })

    if (result.kind == 'ok') {
      const data = result.data?.data || []
      const columns = columnsSummaryItem(currentPage, PAGE_SIZE.current)
      console.log(columns)

      // return result.data || {}
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, activeKey.toString())

      // สร้างไฟล์ excel และดาวน์โหลด
      XLSX.writeFile(wb, `summary-of-order(by-${activeKey}).xlsx`)
    }
  }

  let toolBar: any[] = []
  toolBar = [
    'Total QTY:',
    <Tag
      key="total_qty"
      color="#cd201f"
      style={{ fontSize: 16, borderRadius: 5 }}
    >
      {parseFloat(total_qty).toLocaleString()}
    </Tag>,
    'Total Amount:',
    <Tag
      key="total_amount"
      color="#cd201f"
      style={{ fontSize: 16, borderRadius: 5 }}
    >
      {total_amount.toLocaleString()}
    </Tag>,
  ]
  if (userData.role_name != 'owner') {
    toolBar.push(
      <Button
        key="button"
        icon={<DownloadOutlined />}
        type="primary"
        style={{
          backgroundColor: '#10c469',
          borderColor: '#10c469',
        }}
        onClick={() => {
          exportToExcel()
        }}
      >
        Export
      </Button>,
    )
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          routes: [
            {
              path: '',
              breadcrumbName: 'Summary of Order',
            },
          ],
        },
      }}
    >
      <ProTable<SummaryItem>
        columns={
          activeKey == 'item'
            ? columnsSummaryItem(currentPage, PAGE_SIZE.current)
            : columnsSummaryPo(currentPage, PAGE_SIZE.current)
        }
        actionRef={actionRef}
        scroll={{ x: activeKey == 'item' ? 1800 : 1650 }}
        request={request}
        editable={{
          type: 'multiple',
        }}
        // columnsState={{
        //   persistenceKey: 'pro-table-singe-demos',
        //   persistenceType: 'localStorage',
        //   onChange(value) {
        //     console.log('value: ', value)
        //   },
        // }}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: PAGE_SIZE.current,
          onChange: (page) => {
            setCurrentPage(page)
          },
        }}
        dataSource={items}
        // defaultData={[]}
        dateFormatter="string"
        options={{
          density: true,
        }}
        search={{
          labelWidth: 'auto',
        }}
        toolbar={{
          menu: {
            type: 'tab',
            activeKey: activeKey,
            items: [
              {
                key: 'po',
                label: <span>By P.O</span>,
              },
              {
                key: 'item',
                label: <span>By Item</span>,
              },
            ],
            onChange: (key) => {
              setCurrentPage(1)
              setActiveKey(key as string)
              actionRef.current?.reload()
            },
          },
        }}
        toolBarRender={() => toolBar}
      />
    </PageContainer>
  )
}

export default SummaryOfOrder
